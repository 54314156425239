// import { hello } from './pages/hello';
import jQuery from 'jquery';
// import * as ReactDOM from 'react-dom/client';

import { searchForm } from './components/search-form/search-form';
import { showFixtureEntries } from './components/fixtures/show-fixture-entries';
import './components/fixtures/google-maps';
// import { loginForm } from './components/login-form/login-form';
import 'slick-carousel-latest';
// import {MediumPost, MediumClick} from './src/component';

// import '../styles/main.scss';

const Oie = {

    state: {
        searchForm: false,
        // loginForm: false
    },
    displaySearchForm: () => {

        if( ! Oie.state.searchForm ) {

            const search = searchForm();

            jQuery('body').prepend( search );
            setTimeout(function(){
                jQuery('#search-form-bg').addClass('active');
                jQuery('.search-form-input input').focus();
            }, 50);
            jQuery('body').css('overflow', 'hidden');

            Oie.state.searchForm = true;

        }

    },
    // displayLoginForm: () => {
    //
    //     if( ! Oie.state.loginForm ) {
    //
    //         const login = loginForm();
    //
    //         jQuery('body').prepend( login );
    //         setTimeout(function(){
    //             jQuery('#login-form-bg').addClass('active');
    //             jQuery('.login-form-input input').focus();
    //         }, 50);
    //         jQuery('body').css('overflow', 'hidden');
    //
    //         Oie.state.loginForm = true;
    //
    //     }
    //
    // },
    removeSearchForm: () => {

        Oie.state.searchForm = false;

        jQuery('#search-form-bg').removeClass('active');
        jQuery('.search-form-input').remove();
        setTimeout(function(){
            jQuery('#search-form-bg').remove();
        }, 150);
        jQuery('body').css('overflow', 'auto');

    },
    toggleResponsiveMenu: () => {
        jQuery('#menu-main').slideToggle();
    },
    toggleLogin: () => {
        jQuery('.rul_dropdown_menu').css({display: 'block', opacity: 1});
        jQuery('#rul_login_email').focus();
    }

}

const App = () => {

    showFixtureEntries();

    if( jQuery('.oie-slider').length ) {

        jQuery('.oie-slider').each(function(i, slider){
            jQuery(this).slick({
                infinite: true,
                dots: false,
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000
            });
        });

    }

    jQuery('#search-trigger').on('click', function(){
        Oie.displaySearchForm();
    });

    // jQuery('.login-trigger').on('click', function(){
    //     Oie.displayLoginForm();
    // });

    jQuery('body').on('click', '#search-form-bg', function(){
        Oie.removeSearchForm();
    });

    jQuery('.menu-responsive-ham').on('click', function(){
        Oie.toggleResponsiveMenu();
    });

    jQuery('#oie-open-login').on('click', function(){
        Oie.toggleLogin();
    });

    // const root = ReactDOM.createRoot(document.querySelector('#react-cont'));
    // const root2 = ReactDOM.createRoot(document.querySelector('#react-cont-2'));
    //
    // root.render(<MediumPost/>);
    // root2.render(<MediumClick clicks="5"/>);

}

(function() {
    jQuery(document).ready(function(){
        App();
    });
})( jQuery );

export const searchForm = () => {

    let html = `
        <div id="search-form-bg"></div>
        <div id="search-form">
            <div class="container">
                <form role="search" method="get" class="search-form" action="${ oieMain.siteurl }">
                <div class="search-form-input">
                    <input type="text" placeholder="Search" name="s" />
                    <button type="submit" class="search_button"></button>
                </div>
                </form>
            </div>
        </div>
        `;
    return html;

}

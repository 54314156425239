export const showFixtureEntries = () => {

    jQuery('.oie_single_fixture_entries_container').hide();

    jQuery('#show-fixture-entries, #oie-user-menu-open-entries').on('click', function(e){
        e.preventDefault();
        jQuery(this).toggleClass('active');
        jQuery('.oie_single_fixture_entries_container').slideToggle();
    });

}

function oieGmapsInitialize() {

    var lat      = oieGmaps.lat,
        lon      = oieGmaps.long,
        mName    = oieGmaps.venue,
        mDate    = oieGmaps.date,
        mAddress = oieGmaps.county ? oieGmaps.address + ', Co. ' + oieGmaps.county : oieGmaps.address,
        latlng = new google.maps.LatLng(lat, lon),
        myOptions = {
            zoom: 12,
            center: latlng,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        },
        map = new google.maps.Map( document.getElementById("fixture-map"), myOptions ),
        image = oieGmaps.flag,
        marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: image,
            title: mName
        }),
        contentString = '<div id="content">'+
            '<div id="siteNotice">'+ mName + '</div>'+
            '<div id="bodyContent">' +
                '<p><strong>' + mDate + '</strong></p>' + '<p><strong>' + mAddress + '</strong></p>'+
            '</div>'+'</div>',
        infowindow = new google.maps.InfoWindow({
            content: contentString
        });

    google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(map,marker);
    });

}
if( oieMain.issifix ) {
    window.onload = oieGmapsInitialize
}
